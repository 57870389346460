import React from "react";
import img1 from "../../../assets/imgs/terceirizacao/4.jpg";
import img2 from "../../../assets/imgs/terceirizacao/5.jpg";
import img3 from "../../../assets/imgs/terceirizacao/6.jpg";
import img4 from "../../../assets/imgs/terceirizacao/7.jpg";

const slide2 = () => {
  return (
<section className="w-full px-3 flex flex-col items-center justify-center min-h-screen relative">
  <div
    className="absolute left-0 bottom-0 bg-no-repeat hidden xl:block"
    style={{
      backgroundImage: `url(${img2})`,
      backgroundSize: '250px', // Set the size of the image
      width: '250px', // Match the size of the image
      height: '250px',
      zIndex: 10, // Set the z-index
      transform: 'translateX(-100px)', // Move the image further to the left
    }}
  />
      <div className="w-full max-w-7xl grid grid-cols-5 text-left min-h-screen py-20 gap-8">
        <div className="col-span-2 flex flex-col justify-center xl:justify-start">
          <div>
            <img
              src={img1}
              alt="ADX Software"
              className="w-96 h-auto mt-4 xl:mt-0"
            />
          </div>

          <div className="xl:pl-36 pr-20 z-20">
            <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
              Vistoria de
              <br />
              local
            </h1>
            <p className="text-xs">
              Funcionalidade voltada para registro de locais através de QR Code.
              Nela, o colaborador irá escolher o local a ser realizada a
              inspeção, registrando com fotos e observações, através de
              checklists práticas e objetivas.
            </p>
          </div>
        </div>
        <div className="col-span-3 grid grid-rows-2">
          <div className="grid grid-cols-2">
            <div>
              <img
                src={img3}
                alt="ADX Software"
                className="w-80 h-auto mt-4 xl:mt-0"
              />
            </div>
            <div className="flex flex-col xl:justify-end justify-center">
              <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
                inspeção de
                <br />
                colaborador
              </h1>
              <p className="text-xs">
                Com gráficos, relatórios e indicadores a Audax Terceirização de
                Serviços apresenta as operações da empresa, levantando índices
                de produtividade dos seus colaboradores, os principais problemas
                da organização, bem como tantas outras informações relevantes
                para a gestão.
              </p>
            </div>
          </div>
          <div className="flex flex-row xl:pl-16">
            <div className="flex flex-col justify-end">
              <img
                src={img4}
                alt="ADX Software"
                className="min-w-36 xl:min-w-40 h-auto"
              />
            </div>
            <div className="flex flex-col justify-end xl:pl-5 pl-0 pb-10">
              <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
                vistoria de
                <br />
                veículos
              </h1>
              <p className="text-xs pr-40">
                Registre as condições dos veículos da sua companhia por meio de
                fotos, garantindo a eficiência no processo de vistoria e
                verificação de ordem dos equipamentos do automóvel (lanterna,
                luz de freio, bateria, entre outros).
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default slide2;
