import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
import Slide1 from "./slides/slide1";
import Slide2 from "./slides/slide2";
import Slide3 from "./slides/slide3";
import Slide4 from "./slides/slide4";
import Slide5 from "./slides/slide5";


const slides = [
  { id: 1, content: <Slide1 /> },
  { id: 2, content: <Slide2 /> },
  {
    id: 3,
    content: <Slide3 />,
  },
  {
    id: 4, 
    content: <Slide4 />,
  },
  {
    id: 5,
    content: (
      <Slide5 />
    ),
  },
];

function ContinuousSlider() {
  const swiperRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const toggleAutoplay = () => {
    const swiper = swiperRef.current.swiper;

    if (isPlaying) {
      swiper.params.speed = 100; // Set speed to super fast
      swiper.slideNext(); // Immediately move to the next slide with the new speed
      swiper.autoplay.stop(); // Stop autoplay
    } else {
      swiper.params.speed = 3400; // Reset to original speed
      swiper.autoplay.start(); // Start autoplay again
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <div className="relative h-full -mb-20">
      <Swiper
        ref={swiperRef}
        modules={[Autoplay]}
        spaceBetween={0}
        slidesPerView="auto"
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        speed={12400} // Initial speed for transition
        loop={true} // Infinite loop
        allowTouchMove={true} // Enable mouse/touch dragging
        onTransitionStart={(swiper) => {
          swiper.wrapperEl.style.transitionTimingFunction = "linear";
        }}
        onTransitionEnd={(swiper) => {
          swiper.wrapperEl.style.transitionTimingFunction = "linear";
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>{slide.content}</SwiperSlide>
        ))}
      </Swiper>

      {/* Play/Pause Button */}
      <button
        onClick={toggleAutoplay}
        className="absolute top-4 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white p-2 rounded-full z-10"
      >
        {isPlaying ? "Pause" : "Play"}
      </button>
    </div>
  );
}

export default ContinuousSlider;
