import React from "react";
import banner from "../../assets/imgs/Vigilante-Patrimonial.jpg";
import BannerComponent from "../../components/rotatebanner/bannercomponent3";
import imggestao from "../../assets/imgs/gestao/4.jpg";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import YouTubeVideo from "../../components/youtubevideo";
import imgphone from "../../assets/imgs/gestao/5.jpg";

const gestao = () => {
  return (
    <div>
      <div className="hidden md:block"></div>
      <div className="mx-auto pb-20">
        <Header />
        <BannerComponent />
        <div className="md:hidden">
          <img src={banner} alt="Banner" className="w-full" />
          <div class="firstsec firstsec-mobile">
            <div className="relative -mt-80 sm:hidden py-10 text-left p-8">
              <h1 className="text-customPurple font-sans text-xl">
                <b>VOCÊ SABE</b>
                <br />
                ONDE SUA EQUIPE
                <br />
                <b>ESTÁ AGORA?</b>
              </h1>
              <p className="pt-4 text-customPurple">
                A Toolbit é a melhor e mais
                <br />
                completa tecnologia do mercado, e<br />
                estamos 100% focados no setor de <br />
                terceirização de serviços e<br />
                vigilância patrimonial
              </p>
              <button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
                SOLICITE SEU DIAGNÓSTICO
              </button>
            </div>
          </div>
        </div>
        <div className="bg-customPurple py-10 bg-pattern flex flex-col items-center">
          <div className="items-center md:w-full  md:max-w-[90vw] md:grid md:grid-cols-2 z-40 p-8">
            <div>
              <p className="flex flex-col text-white font-bold sm:text-left justify-center h-full sm:pr-10 sm:2xl">
                O nosso software acompanha
                
                qualquer tipo de atividade
                
                executada pelos seus
                
                colaboradores, tudo isso por
               
                meio de uma ferramenta
                
                customizável e capaz de
                
                atender todas as
               
                necessidades dos seus
                
                clientes.
              </p>
            </div>
            <div className="flex flex-col">
              {" "}
              <div>
                <img
                  src={imggestao}
                  alt="dashboard"
                  className="pt-10 sm:pt-0 z-10 h-full w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 py-8">
          <h1 className="text-2xl font-bold text-customPurple pb-4 sm:text-4xl sm:pt-14 ">
            Soluções para<br/>qualquer operação
          </h1>
          <p className="mt-2 text-black font-normal sm:hidden">
            Entre elas estão:
          </p>
        </div>
        <div className="bg-gray-100 mx-auto items-center pt-7 px-[6vw] pb-4">
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
    
    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="flex text-customPurple font-semibold sm:text-xl">
        ROTAS E ROTAS EXTRAS
      </div>
      <p>
        Nossa solução cria rotas para atender seus clientes, indicando quais são rotas extras e quais clientes precisam ser atendidos.
      </p>
    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="text-customPurple font-semibold sm:text-xl">
        SOLICITAÇÃO DE DEMANDA
      </div>
      <p>
        Possui clientes que não têm frequência de pedidos? Através da solicitação de demanda, é possível criar visitas pontuais ao cliente.
      </p>
    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="text-customPurple font-semibold sm:text-xl">
        INCONSISTÊNCIAS
      </div>
      <p>
        É sempre importante indicar inconsistências de peso e de quantidade de recipientes recebidos ou entregues ao cliente.
      </p>
    </div>

  </div>

  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
    
    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="text-customPurple font-semibold sm:text-xl">
        CONFERÊNCIA DE PESO
      </div>
      <p>
        Em nossas soluções levamos a pesagem muito a sério, integrando a balança da sua operação ao nosso sistema de gestão.
      </p>
    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="text-customPurple font-semibold sm:text-xl">
        ROMANEIO DIÁRIO
      </div>
      <p>
        Informe dados para os seus motoristas e ajudantes, indicando a quantidade de recipientes solicitados pelos seus clientes.
      </p>
    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="text-customPurple font-semibold sm:text-xl">
        GERENCIAMENTO DE RECIPIENTE
      </div>
      <p>
        Saiba a localização dos diversos recipientes utilizados pela sua operação, e vitando gastos e mantendo o patrimônio seguro.
      </p>
    </div>

  </div>
</div>

        <h1 className="m-8 text-2xl font-bold text-customPurple mb-4 mt-20 sm:text-4xl">
        MULTIPLATAFORMA
        </h1>
        <p className="px-5 mx-4 md:mx-[20vw] sm:text-2xl">
        Através de funcionalidades mobile, permita que as informações sejam recolhidas sem a necessidade de papéis ou do retorno a um computador.
        </p>
        <div className="flex flex-col items-center mt-24">
          <img
            src={imgphone}
            alt="ADX Dashboard"
            className="h-[40vw] md:h-60 w-auto -mt-14"
          />
        </div>
       
        <div className="md:-mt-0 flex flex-col items-center">
          <div className="mt-20 sm:-mt-0 container px-6 md:px-48 md:py-8 z-40">
            {" "}
            <YouTubeVideo videoId="LV2-cjRpzfU" />
            <p className="px-7 hidden sm:block pt-10 sm:text-2xl">
        Tenha em mãos vários tipo de relatórios gerenciais, desde o acompanhamento do planejado e o executado, as coletas que acontecerem no dia, movimentações, excesso de peso, dentre tantos outros
        </p>
          </div>
        </div>

      </div>
      <Footer />

    </div>
  );
};

export default gestao;
