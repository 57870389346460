import React from "react";
import img1 from "../../../assets/imgs/food/19.jpg";
import img2 from "../../../assets/imgs/food/20.jpg";

const slide5 = () => {
  return (
    <section className="w-full flex flex-col items-center justify-between min-h-screen relative">
      <div className="w-full max-w-7xl grid grid-cols-2 text-left min-h-screen py-20 gap-8">
        <div className="flex flex-col justify-start">
          <img src={img1} alt="ADX Software" />
          <div className="z-20 pt-10">
            <h1 className="text-customPurple text-xl font-bold uppercase mb-2 -mt-14">
              Registro diário de
              <br />
              ocorrências (rdo)
            </h1>
            <p className="text-xs">
              A partir do RDO, ou Registro Diário de Ocorrência, o gestor
              acompanha de forma eficiente o tratamento das ocorrências de grau
              maior, que requerem um acompanhamento mais detalhado das não
              conformidades que surgem ao longo dos processos. Seus
              colaboradores podem informar a categoria da ocorrência, como por
              exemplo de acidentes ou fardamento, e adicionar observações sobre
              o problema, além da possibilidade de realizar a anexação de
              imagens. Vale ressaltar que as categorias de ocorrências do RDO
              são customizáveis de acordo com as necessidades da sua empresa de
              refeição coletiva, visando a otimização do trabalho do gestor.
            </p>
          </div>
        </div>
        <div>
          <img src={img2} alt="ADX Software" />
          <div className="z-20 pt-5">
            <h1 className="text-customPurple text-xl font-bold uppercase mb-2 -mt-14">
              Relatório
            </h1>
            <p className="text-xs">
              A partir dos relatórios de checklists (Auditoria, Segurança do
              trabalho, etc) o gestor pode visualizar de forma prática e
              eficiente as evidências das informações coletadas. O que facilita
              muito a resolução de possíveis problemas que surgem ao longo da
              operação.
              <br />
              <br />
              As informações desses relatórios são coletadas pelos colaboradores
              no aplicativo. Em seguida, as entidades responsáveis, como
              gestores ou supervisores, podem ver essas informações no painel
              administrativo.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default slide5;
