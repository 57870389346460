import img1 from "../../../assets/imgs/terceirizacao/1.jpg";
import img2 from "../../../assets/imgs/terceirizacao/2.jpg";
import img3 from "../../../assets/imgs/terceirizacao/3.jpg";

import React from "react";

const slide1 = () => {
  return (
    <section className="px-3 w-full flex flex-col items-center justify-center min-h-screen">
      <div className="w-full max-w-7xl grid grid-cols-3 text-left min-h-screen py-20 gap-8">
        <div className="flex flex-col justify-center xl:justify-between">
          <div>
            <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
              dashboard e<br />
              app
            </h1>
            <p className="text-xs">
              Você vai conseguir acompanhar tudo pelo painel gerencial enquanto
              sua equipe de campo usa um aplicativo Android para registro das
              atividades na ponta.
              <br />
              <br />
              Você vai conseguir controlar a agenda, falar com seus
              colaboradores e clientes, enxergar métricas através de relatórios
              e gráficos e ter a certeza que até o cliente do seu cliente está
              satisfeito com a sua prestação de serviços
            </p>
          </div>
          <div>
            <img
              src={img1}
              alt="ADX Software"
              className="w-96 xl:min-w-max h-auto mt-4 xl:mt-0"
            />
          </div>
        </div>
        <div className="flex flex-col xl:justify-end">
          <img src={img2} alt="ADX Software" className="mb-4 mr-8" />
          <div className="pl-0 xl:pl-28 pb-10">
            <h1 className="text-customPurple text-xl font-bold uppercase mb-2">
              Controle da rotina
              <br />
              diária e produtividade
              <br />
              dos colaboradores
            </h1>
            <p className="text-xs">
              A agenda é uma ferramenta essencial que o supervisor irá utilizar
              como forma de organização e direcionamento, para que assim seja
              possível saber para onde ele deve ir nas suas próximas visitas.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div>
            <h1 className="text-xl text-customPurple font-bold uppercase mb-2">
              checklist
              <br />
              customizável
            </h1>
            <p className="text-xs">
              Garante total aproveitamento operacional, evitando desperdício de
              tempo e proporcionando um serviço completamente customizável.
              <br />
              Ninguém conhece seu negócio como você, e com essa funcionalidade
              você terá a oportunidade imperdível de criar suas próprias
              checklists, com foto em tempo real para dupla checagem da
              veracidade da informação sem poder puxar da galeria
            </p>
          </div>

          <img src={img3} alt="ADX Asset" className="w-auto h-auto max-h-96" />
        </div>
      </div>
    </section>
  );
};

export default slide1;
