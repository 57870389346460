import img1 from "../../../assets/imgs/food/7.jpg";
import img2 from "../../../assets/imgs/food/10.jpg";

import React from "react";

const slide1 = () => {
  return (
    <section className="px-3 w-full flex flex-col items-center justify-center min-h-screen ">
      <div className="w-full max-w-7xl grid grid-cols-3 text-left min-h-screen py-20 gap-8">

        <div className="flex flex-col justify-center">
          <div><h1 className="text-customPurple text-xl font-bold uppercase mb-2">Dashboard</h1>
          <p>Nosso Dashboard Customizável oferece uma visão rápida e abrangente do desempenho da sua equipe. Com ele, você terá acesso imediato à localização dos seus colaboradores, acompanhamento de ocorrências, gestão de agendas, análise de métricas por meio de gráficos detalhados e relatórios completos, e uma gama de funcionalidades adicionais para otimizar a sua gestão.</p></div>
          <div>
            <img
              src={img1}
              alt="ADX Software"
              className="w-96 xl:min-w-max h-auto mt-4 xl:mt-0"
            />
          </div>
        </div>

        <div className="flex flex-col xl:justify-end">
          <img src={img2} alt="ADX Software" className=" mr-8" />
        </div>

        <div className="flex flex-col justify-center">
          <div>
            <h1 className="text-xl text-customPurple font-bold uppercase mb-2">
              checklist
              <br />
              customizável
            </h1>
            <p className="text-xs">
              Garante total aproveitamento operacional, evitando desperdício de
              tempo e proporcionando um serviço completamente customizável.
              <br />
              Ninguém conhece seu negócio como você, e com essa funcionalidade
              você terá a oportunidade imperdível de criar suas próprias
              checklists, com foto em tempo real para dupla checagem da
              veracidade da informação sem poder puxar da galeria
            </p>
          </div>

        </div>
      </div>
    </section>
  );
};

export default slide1;
