import React from "react";
import img1 from "../../../assets/imgs/food/11.jpg";
import img2 from "../../../assets/imgs/food/12.jpg";
import img3 from "../../../assets/imgs/food/13.jpg";

const slide2 = () => {
  return (
    <section className="w-full px-3 flex flex-col items-center justify-center min-h-screen relative">
      <div className="w-full max-w-7xl grid grid-cols-5 text-left min-h-screen py-20 gap-8">
        <div className="grid grid-cols-5 col-span-2">
          <div className="flex flex-col justify-end relative col-span-3">
            <img src={img1} alt="ADX Software" className="w-auto h-auto pr-5" />
          </div>

          <div className="flex flex-col justify-center col-span-2 z-20 -ml-20">
            <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
              Checklist
              <br />
              customizável
            </h1>
            <p className="text-xs">
              Fundamental para garantir a máxima eficiência operacional e evitar
              qualquer desperdício de tempo, esta funcionalidade é essencial
              para fornecer um serviço altamente personalizado.
              <br />
              <br /> Ninguém conhece o seu negócio tão bem quanto você e, com
              essa ferramenta, você terá a liberdade de criar suas próprias
              vistorias. Além disso, ela oferece recursos avançados, como
              captura de fotos em tempo real, sem acesso a galeria do celular,
              rastreamento de localização e muito mais.
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-around col-span-3">
          <div className="grid grid-cols-2 justify-center">
            <div>
              <img
                src={img2}
                alt="ADX Software"
                className=" h-auto w-auto  xl:mt-0"
              />
            </div>
            <div className="flex flex-col justify-center">
              <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
                Checklist
                <br />
                gerais
              </h1>
              <p className="text-xs">
                Aprimore a eficiência da sua equipe ao automatizar as vistorias
                diárias rotineiras e estabelecer métricas e acompanhamentos para
                cada fase da produção. Monitore aspectos cruciais, como higiene
                pessoal, temperatura dos alimentos, avaliações sensoriais,
                rastreabilidade diária e uma variedade de outros controles
                essenciais.
              </p>
            </div>
          </div>

          <div className="flex flex-row xl:pl-16">
            <div className="flex flex-col justify-end">
              <img
                src={img3}
                alt="ADX Software"
                className="min-w-36 xl:min-w-40 h-auto"
              />
            </div>
            <div className="flex flex-col justify-end xl:pl-5 pl-0 pb-10">
              <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
                Checklist
                <br />
                Auditorias
              </h1>
              <p className="text-xs pr-40">
                Conduza auditorias em suas filiais ou unidades para analisar
                aspectos que vão desde a adequação estrutural até a gestão dos
                resíduos gerados e sua destinação. <br />
                <br />
                Tudo de maneira prática e em conformidade com as regulamentações
                dos órgãos competentes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default slide2;
