import React from "react";
import img1 from "../../../assets/imgs/terceirizacao/16.jpg";
import img2 from "../../../assets/imgs/terceirizacao/17.jpg";
import img3 from "../../../assets/imgs/terceirizacao/18.jpg";

const slide6 = () => {
  return (
    <section className="w-full flex flex-col items-center justify-center min-h-screen relative">
      <div className="w-full max-w-7xl grid grid-cols-3 text-left min-h-screen py-20 gap-8">
        <div className="flex flex-col justify-center xl:justify-start">
          <img src={img1} alt="ADX Softare" />
          <h1 className="font-bold text-customPurple text-xl mb-2 uppercase">
            Toolbit Client
          </h1>
          <p className="text-xs">
            A funcionalidade Toolbit Client no painel administrativo refere-se à
            interação com a nossa página web de abertura de chamados, o
            HelpDesk.
            <br />
            <br />O Toolbit Client é a principal forma de comunicação entre a
            sua empresa e seus clientes. Seus clientes podem abrir solicitações
            por categoria. A Helpdesk do Toolbit Client fará com que o seu
            cliente possa dar informações sobre eventuais problemas na sua
            operação.
          </p>
        </div>
        <div className="flex flex-col justify-center xl:justify-center">
          <div>
            <img src={img2} alt="ADX Softare" className="w-auto max-h-96" />
          </div>
          <div className="text-left xl:pl-56">
            <h1 className="font-bold uppercasetext-xl mb-2 text-customPurple xl:-mt-48 uppercase">
              Satisfação
              <br />
              do cliente
              <br />
              externo
            </h1>
            <p className="text-xs xl:pt-0 ">
              Com essa funcionalidade, você fica por dentro do nível de
              satisfação do usuário do cliente externo que, a partir da nossa
              ferramenta, pode avaliar o seu serviço. 
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center xl:justify-center">
          <img src={img3} alt="ADX Softare" />
          <div className="pl-20 pr-10">
            <h1 className="font-bold uppercasetext-xl mb-2 text-customPurple uppercase">
              Contato com o
              <br />
              cliente
            </h1>
            <p className="text-xs">
            Funcionalidade utilizada pelo supervisor para verificação da satisfação do cliente, onde este cliente pode oferecer opiniões e sugestões sobre o serviço prestado. O seu cliente assina na tela do celular confirmando a visita e o feedback

            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default slide6;
