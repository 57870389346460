import React from "react";
import img1 from "../../../assets/imgs/terceirizacao/14.jpg";
import img2 from "../../../assets/imgs/terceirizacao/15.jpg";
import img3 from "../../../assets/imgs/terceirizacao/dots.jpg";

const slide5 = () => {
  return (
    <section className="w-full flex flex-col items-center justify-between min-h-screen relative">
      <div className="w-full max-w-7xl grid grid-cols-3 text-left min-h-screen py-20 gap-8">
        <div>
          <div className="-ml-7">
            <img src={img1} alt="ADX Software" />
          </div>
          <div>
            <h1 className="text-customPurple font-bold text-xl uppercase mb-2">
              Gestão do
              <br />
              SESMT
            </h1>
            <p className="text-xs">
              Com essa funcionalidade, o técnico de segurança do trabalho tem
              facilidade e agilidade na gestão de SESMT (Segurança e Medicina do
              Trabalho), já que a partir da nossa plataforma é possível realizar
              diversos procedimentos técnicos relevantes à área.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-end">
          <div>
            <img src={img2} alt="ADX Software" />
          </div>
        </div>

        <div className="flex flex-col justify-end xl:pb-32 pb-20" >
          <h1 className="text-customPurple font-bold text-xl uppercase mb-2">
            Ronda
            <br />
            programada e<br />
            botão de pânico
          </h1>
          <p className="text-xs">
            Com essa funcionalidade, o técnico de segurança do trabalho tem
            facilidade e agilidade na gestão de SESMT (Segurança e Medicina do
            Trabalho), já que a partir da nossa plataforma é possível realizar
            diversos procedimentos técnicos relevantes à área.
          </p>
        </div>
      </div>
      <div
    className="absolute right-0 bottom-0 bg-no-repeat hidden xl:block"
    style={{
      backgroundImage: `url(${img3})`,
      backgroundSize: '190px', // Set the size of the image
      width: '190px', // Match the size of the image
      height: '190px',
      zIndex: 30, // Set the z-index
      transform: 'translateX(100px)', // Move the image further to the left
    }}
  />
      
    </section>
  );
};

export default slide5;
