import React from "react";
import img1 from "../../../assets/imgs/food/17.jpg";
import img2 from "../../../assets/imgs/food/18.jpg";
import img3 from "../../../assets/imgs/terceirizacao/13.jpg";

const slide4 = () => {
  return (
    <section className="w-full flex flex-col items-center justify-center min-h-screen relative">
      <div className="w-full max-w-7xl grid grid-cols-3 text-left min-h-screen py-20 gap-8">
        <div className="col-span-1 flex flex-col justify-end py-10">
          <img src={img1} alt="ADX Software" />
        </div>
        <div className="flex flex-col col-span-2 justify-center py-10">
          <div className="grid grid-cols-2">
            <div className="z-20">
              <h1 className="text-customPurple text-xl font-bold uppercase mb-2 -mt-14">
                Agenda de
                <br />
                higienização
              </h1>
              <p className="text-xs">
                Cadastre, acompanhe e visualize todas as higienizações
                necessárias para os equipamentos, ambientes ou áreas de sua
                operação.
                <br />
                <br />
                Evite que qualquer informação passe despercebida, pois isso
                poderia resultar em problemas com a vigilância sanitária.
              </p>
            </div>
            <div className="-ml-40 -mt-20">
              <img src={img2} alt="ADX Software" className="h-auto w-auto"/>
            </div>
          </div>

          <div>
          <div className="z-20">
              <h1 className="text-customPurple text-xl font-bold uppercase mb-2 -mt-14">
                Não-
                <br />
                conformidades
              </h1>
              <p className="text-xs pr-40">
              Pensando justamente em entregar a resolução mais rápida para os problemas que podem vir a surgir no andamento da sua operação, nossos checklists de registro promovem agilidade e eficácia no tratamento das não conformidades. A partir da realização de checklists o colaborador pode registrar o surgimento das não conformidades, anexando imagens, descrição informativa da situação e expondo qual será o plano de ação aplicado para resolução eficiente do problema.
              <br/><br/>
Esse registro possibilita que os problemas sejam rapidamente solucionados e não atrapalhem o fluxo de atividades dos colaboradores, garantindo a fluidez da operação e alta qualidade dos produtos oferecidos.
              </p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default slide4;
