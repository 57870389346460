import React from "react";
import img1 from "../../../assets/imgs/terceirizacao/8.jpg";
import img2 from "../../../assets/imgs/terceirizacao/9.jpg";
import img3 from "../../../assets/imgs/terceirizacao/10.jpg";
import img4 from "../../../assets/imgs/terceirizacao/7.jpg";

const slide3 = () => {
  return (
    <section className="w-full flex flex-col items-center justify-center min-h-screen relative">
      <div className="absolute left-0 top-10 w-40 h-40 bg-customPurple rounded-full z-10 transform -translate-x-24 hidden xl:block" />
      <div className="w-full max-w-7xl grid grid-cols-3 text-left min-h-screen py-20 gap-8">
        <div className="flex flex-col justify-center xl:justify-between">
          <div className="z-20 pl-0 xl:pl-14">
            <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
              Acompanhamento de
              <br />
              não-conformidades
            </h1>
            <p className="text-xs">
              Reduza em até 100% as multas e sanções aplicadas pelos órgãos
              regulamentadores e seus clientes. Atue proativamente e
              preventivamente nos processos de certificação e recertificação das
              ISOS.
            </p>
            <ul className="list-disc list-inside pt-2 text-xs">
              <li>Tenha o controle total das não conformidades;</li>
              <li>Identifique problemas na operação;</li>
              <li>Atue em conformidades com as sla’s contratadas.</li>
            </ul>
          </div>
          <div>
            <img
              src={img1}
              alt="ADX Software"
              className="w-auto h-auto max-h-96 mt-4 xl:mt-0 xl:pb-14"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center xl:justify-center">
          <div>
            <img
              src={img2}
              alt="ADX Software"
              className="w-auto h-auto max-h-96 mt-4 xl:mt-0"
            />
          </div>
          <div className="pl-0 xl:pl-14">
            <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
              Registro diário de
              <br />
              ocorrência (rdo)
            </h1>
            <p className="text-xs">
              Funcionalidade voltada para o registro de irregularidades internas
              e externas, garantindo o acompanhamento eficiente das mesmas até o
              fechamento da ocorrência.
              <br />
              <br />
              Você vai enxergar como uma sinaleira suas ocorrências e
              categorizar de acordo com a sua operação
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center xl:justify-between">
          <div className="pl-0 xl:pl-10">
            <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
              Livro de
              <br />
              Ocorrência
            </h1>
            <p className="text-xs">
              Substitui o registro manual e tem a função de<br />relatar de forma
              digitalizada as atividades realizadas pelos funcionários, além dos
              dias e locais de ocorrência.
            </p>
          </div>
          <div>
            <img
              src={img3}
              alt="ADX Software"
              className="w-auto h-auto max-h-96 mt-4 xl:mt-0"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default slide3;
