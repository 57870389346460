import React from "react";
import img1 from "../../../assets/imgs/terceirizacao/19.jpg";
import img2 from "../../../assets/imgs/terceirizacao/20.jpg";

const slide7 = () => {
  return (
    <section className="w-full flex flex-col items-center justify-center min-h-screen relative">
      <div className="w-full max-w-7xl grid grid-cols-3 text-left min-h-screen py-20 gap-8">
        <div>
          <img src={img1} alt="ADX Software" />
        </div>

        <div className="flex flex-col justify-between">
          <div>
            <h1 className="font-bold uppercasetext-xl mb-2 text-customPurple uppercase">
              Relatórios
            </h1>
            <p className="text-xs xl:pt-0 ">
              Com vários gráficos, relatórios e indicadores a Toolbit apresenta
              de forma prática, rápida e objetiva as operações, a produtividade
              dos seus servidores, os principais e os mais frequentes problemas
              operacionais, bem como tantas outras informações relevantes para
              gestão. Você vai conseguir medir a produtividade dos seus
              colaboradores dentro dos seus clientes e até cadastrar o e-mail do
              seu cliente para ele receber mensal e automaticamente um relatório
              com o resumo de tudo que foi realizado lá no último mês. Um
              detalhe importante, nossos relatórios saem com a logomarca da sua
              empresa.
            </p>
          </div>
          <div className="xl:pb-20 pl-20">
            <h1 className="font-bold uppercasetext-xl mb-2 text-customPurple uppercase">
              Capinação
            </h1>
            <p className="text-xs xl:pt-0 pr-20">
              Na funcionalidade “capinação” você pode visualizar as operações de
              capinação com eficiência e praticidade, tendo acesso a fotografias
              e ao tempo final necessário para a realização da limpeza da área.
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-end">
          {" "}
          <img src={img2} alt="ADX Software" />
        </div>
      </div>
    </section>
  );
};

export default slide7;
