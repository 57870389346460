import React from "react";
import banner from "../../assets/imgs/homebanner.jpg";
import bannersm from "../../assets/imgs/homebannersm.jpg";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import svg1 from "../../assets/imgs/divmobile.svg";
import svg2 from "../../assets/imgs/CHECKLIST.svg";
import svg3 from "../../assets/imgs/RDO.svg";
import svg4 from "../../assets/imgs/RELATORIOS.svg";
import svg5 from "../../assets/imgs/RASTREABILIDADE.svg";
import svg6 from "../../assets/imgs/LOCALIZAÇÃO.svg";
import svg7 from "../../assets/imgs/OFFLINE.svg";
import Carousel from "./carousel";
import "./home.css";
import ImageSlider from "./imageslider";
import YouTubeVideo from "../../components/youtubevideo";

const home = () => {
  return (
    <div>
      <Header />
      <h1 className="hidden sm:block bg-black" style={{height: '100vh', maxHeight: '820px' }}>
        <div
          style={{
            backgroundImage: `url(${bannersm})`,
            backgroundSize: "cover",
            backgroundPosition: "top-center",
            height: "100vh",
            maxHeight: "820px", 
          }} 
        >
          {" "}
          <div className="text-left flex flex-col justify-center items-start mx-auto max-w-[90vw] h-full">
            <h1 className="text-customPurple font-sans text-4xl">
              OS <b>MELHORES</b>
              <br></br>E <b>MAIS COMPLETOS</b>
              <br></br>
              <b>SOFTWARES</b> DE<br></br>
              <b>GESTAO OPERACIONAL</b>
            </h1>
            <button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
              SOLICITE SEU DIAGNÓSTICO
            </button>
          </div>
        </div>
      </h1>
      <div className="sm:hidden">
        <img src={banner} alt="Banner" className="w-full" />
        <div class="firstsec firstsec-mobile">
          <div className="relative -mt-60 sm:hidden py-10 text-left p-8 ">
            <h1 className="text-customPurple font-sans text-xl">
              OS <b>MELHORES</b>
              <br></br>E <b>MAIS COMPLETOS</b>
              <br></br>
              <b>SOFTWARES</b> DE<br></br>
              <b>GESTAO OPERACIONAL</b>
            </h1>
            <button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
              SOLICITE SEU DIAGNÓSTICO
            </button>
          </div>
        </div>
      </div>
      <div className="bg-customGray py-6 sm:py-14">
        <div className="pb-4 py-4 sm:pb-10">
          <b className="text-3xl text-CustomPurpleStrong font-black sm:text-4xl">
            +1.000.000
          </b>
          <p className="text-3xl font-medium text-CustomPurpleStrong sm:text-4xl">
            de usuários utilizam<br></br>nossos sistemas
          </p>
        </div>
        <div className="py-5">
          <ImageSlider />
        </div>
      </div>
      <div className="bg-gray-100 p-4 sm:py-14">
        <div className="text-center">
          <img src={svg1} alt="SVG 3" className="-mt-10 sm:-mt-20 w-full h-14 z-10" />
          <h1 className="text-xl font-bold text-customPurple mt-8 sm:text-4xl sm:pb-7">
            Soluções
            <br />
            ADX Company
          </h1>
          <p className="mt-2 text-black font-medium sm:hidden">
            Gerencie e personalize de acordo com
            <br />
            suas necessidades, facilitando o alcance
            <br />
            dos seus objetivos de negócio
          </p>
          <p className="mt-2 text-black font-medium hidden sm:block text-2xl">
            São dezenas de funcionalidades para você gerenciar
            <br />
            e personalizar de acordo com suas necessidades.
            <br />
            Facilitando o alcance dos seus objetivos de negócio.
          </p>
        </div>
        <div className="max-w-[90vw] items-center mx-auto mt-6 space-y-4 px-3 md:space-y-0 md:grid md:grid-cols-2 md:gap-4 text-left pt-10 pb-4">
          <div className="flex items-center bg-white p-4 rounded-lg shadow">
            <div className="mr-4">
              <img src={svg2} alt="Icon" className="sm:h-14"/>
            </div>
            <div className="text-customPurple font-semibold sm:text-xl">
              Checklist customizado
            </div>
          </div>
          <div className="flex items-center bg-white p-4 rounded-lg shadow">
            <div className="mr-4">
              <img src={svg3} alt="Icon" className="sm:h-14"/>
            </div>
            <div className="text-customPurple font-semibold sm:text-xl">
              RDO <br className="md:hidden" />
              (Registro diário de ocorrência)
            </div>
          </div>
          <div className="flex items-center bg-white p-4 rounded-lg shadow">
            <div className="mr-4">
              <img src={svg4} alt="Icon" className="sm:h-14"/>
            </div>
            <div className="text-customPurple font-semibold sm:text-xl">Relatórios</div>
          </div>
          <div className="flex items-center bg-white p-4 rounded-lg shadow">
            <div className="mr-4">
              <img src={svg5} alt="Icon" className="sm:h-14"/>
            </div>
            <div className="text-customPurple font-semibold sm:text-xl">
              Rastreabilidade
            </div>
          </div>
          <div className="flex items-center bg-white p-4 rounded-lg shadow">
            <div className="mr-4">
              <img src={svg6} alt="Icon" className="sm:h-14"/>
            </div>
            <div className="text-customPurple font-semibold sm:text-xl">
              Localização de funcionários
            </div>
          </div>
          <div className="flex items-center bg-white p-4 rounded-lg shadow ">
            <div className="mr-4">
              <img src={svg7} alt="Icon" className="sm:h-14"/>
            </div>
            <div className="text-customPurple font-semibold sm:text-xl">
              Funcionamento Offline
            </div>
          </div>
        </div>
        <div className="mt-10 text-center text-customPurple font-black text-xl sm:text-4xl">
          O que os clientes
          <br />
          falam da nossa plataforma
        </div>
      </div>
      <Carousel/>
      <div className="-mt-20 md:-mt-40 flex flex-col items-center">
        {" "}
        <div className="container px-6 md:px-48 md:py-8 z-40">
          <YouTubeVideo videoId="LV2-cjRpzfU" />
          <p className="text-2xl md:py-10 hidden md:block">Os nossos softwares acompanham qualquer atividade executada pelos seus colaboradores, tudo isso por meio de ferramentas customizáveis e capaz de atender todas as necessidades dos seus clientes.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default home;
