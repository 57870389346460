import React, { useState } from "react";
import img1 from "../../assets/imgs/food/7.jpg";
import img2 from "../../assets/imgs/food/8.jpg";
import img3 from "../../assets/imgs/food/9.jpg";



const Slidermobile = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Slide templates as an array
  const items = [
    // Slide 1: Text with a Background Image
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img1} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Dashboard
        </h2>
        <p className="text-center">
        Nosso Dashboard Customizável oferece uma visão rápida e abrangente do desempenho da sua equipe. Com ele, você terá acesso imediato à localização dos seus colaboradores, acompanhamento de ocorrências, gestão de agendas, análise de métricas por meio de gráficos detalhados e relatórios completos, e uma gama de funcionalidades adicionais para otimizar a sua gestão.
        </p>
      </div>
    </div>,

    // Slide 2: Card Layout with an Image and Description
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img2} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
        centro de custo        </h2>
        <p className="text-center">
        O NutCin possibilita que você  administre todas as unidades de sua  empresa de forma eficiente. Com ele,  você pode cadastrar a matriz da  empresa, as regionais (onde você pode  especificar a localização de cada uma)  e as filiais vinculadas a cada regional.
        </p>
      </div>
    </div>,

    // Slide 3: Simple Quote with a Background Color
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img3} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Checklist<br/>customizável
        </h2>
        <p className="text-center">
        Fundamental para garantir a máxima eficiência operacional e evitar qualquer desperdício de tempo, esta funcionalidade é essencial para fornecer um serviço altamente personalizado. Ninguém conhece o seu negócio tão bem quanto você e, com essa ferramenta, você terá a liberdade de criar suas próprias vistorias. Além disso, ela oferece recursos avançados, como captura de fotos em tempo real, sem acesso a galeria do celular, rastreamento de localização e muito mais.
        </p>
      </div>
    </div>
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="p-6 relative w-full max-w-md mx-auto">
      <div className="flex justify-center items-center rounded-lg">
        {items[currentIndex]}
      </div>
      <div className="absolute inset-0 flex justify-between items-center px-4">
        <button
          onClick={handlePrev}
          className="bg-customPurple p-3 rounded-full  text-white font-bold flex items-center justify-centerw"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          onClick={handleNext}
          className="bg-customPurple p-3 rounded-full  text-white font-bold flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Slidermobile;
