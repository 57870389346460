import React from "react";
import Slider from "react-slick";
import "./home.css";
import img1 from "./../../assets/imgs/home/10.jpg";
import img2 from "./../../assets/imgs/home/11.jpg";
import img3 from "./../../assets/imgs/home/12.jpg";
import img4 from "./../../assets/imgs/home/13.jpg";

const testimonials = [
  {
    text: 
      "“Venho notando que a ferramenta vem sempre trazendo algumas melhorias, como, por exemplo, a checklist customizada. Isso vai trazer uma flexibilidade muito grande para nossos processos de acordo com as inúmeras variáveis que vivenciamos na operação. \nO que tanto eu quanto minha equipe elogiamos bastante, e vejo como um diferencial perante outros sistemas que utilizamos aqui na empresa atualmente, é o suporte de vocês, o pessoal é muito atencioso e tenta sempre resolver as nossas dúvidas.”"
    ,
    author: "Natalia Espindola",
    position: "Gerente de operações",
    company: "Grupo Liderança",
    icon: img4,
  },
  {
    text: "“A ferramenta da ADX Company para gestão operacional, é uma ótima   para controle e gestão de clientes, equipes e materiais.\nAgora, temos a possibilidade de acompanhar com maior precisão toda nossa rotina de serviços, bem como a melhora no nosso planejamento de atividades.”",
    author: "Douglas",
    position: "Coordenador operacional",
    company: "Definitiva Terceirização",
    icon: img3,
  },
  {
    text: "“Desde que iniciamos com a plataforma da ADX Company, nosso serviço mudou! Conseguimos realizar nossas atividades com mais transparência para os clientes, além da praticidade de emitir relatórios com os resultados obtidos mensalmente.\nAinda temos o controle dos funcionários, o que deixou nosso serviço mais organizado.”",
    author: "Emerson Caldas",
    position: "Coordenador operacional",
    company: "Souza e Filhos",
    icon: img2,
  },
  {
    text: "Desde que iniciamos com a plataforma da ADX Company, nosso serviço mudou!\nConseguimos realizar nossas atividades com mais transparência para os clientes, além de emitir relatórios com os resultados obtidos em nosso trabalho.\nAinda temos o controle dos funcionários, o que deixou nosso serviço mais organizado.",
    author: "Thiago",
    position: "Analista de sistemas",
    company: "Segvap",
    icon: img1,
  },
  // Add more testimonials as needed
];

const Carousel = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-customPurple pt-10 pb-20 md:pb-32 bg-pattern">
      <div className="container mx-auto pb-11 max-w-[90vw] justify-between justify-items-center">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="p-4">
              <div className="bg-white p-6 rounded-lg shadow-lg min-h-[400px] flex flex-col justify-between">
                {/* Render text with paragraphs or line breaks */}
                {Array.isArray(testimonial.text) ? (
                  testimonial.text.map((paragraph, i) => (
                    <p key={i} className="text-gray-700 mb-4">
                      {paragraph}
                    </p>
                  ))
                ) : (
                  testimonial.text.split('\n').map((line, i) => (
                    <p key={i} className="text-gray-700 mb-4">
                      {line}
                    </p>
                  ))
                )}
                <div className="mt-auto flex items-center">
                  <img
                    src={testimonial.icon}  
                    alt="Icon"
                    className="w-8 h-8 mr-2"
                  />
                  <div>
                    <div className="text-customPurple font-semibold">
                      {testimonial.author}
                    </div>
                    <div className="text-gray-500 text-sm">
                      {testimonial.position}, {testimonial.company}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
