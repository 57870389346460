import React from "react";
import img1 from "../../../assets/imgs/terceirizacao/11.jpg";
import img2 from "../../../assets/imgs/terceirizacao/12.jpg";
import img3 from "../../../assets/imgs/terceirizacao/13.jpg";

const slide4 = () => {
  return (
    <section className="w-full flex flex-col items-center justify-center min-h-screen relative">
      <div className="w-full max-w-7xl grid grid-cols-3 text-left min-h-screen py-20 gap-8">
        <div className="flex flex-col justify-center xl:justify-center">
          <div>
            <img
              src={img1}
              alt="ADX Software"
              className="w-auto h-auto max-h-96 mt-4 xl:mt-0"
            />
          </div>
          <div className="xl:pr-32">
            <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
              Solicitação de
              <br />
              material
            </h1>
            <p className="text-xs">
              A partir do aplicativo, é possível solicitar os materiais que
              estão em falta no estabelecimento de cada cliente em tempo real.
              Isso facilita a comuncação entre colaborador e gestor, tornando a
              gestão interna muito mais coerente.
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-end xl:justify-end z-10 xl:-ml-32">
          <div className="grid xl:grid-cols-6 items-center">
            <div className="col-span-2">
              <img
                src={img2}
                alt="ADX Software"
                className="w-auto h-auto max-h-96 mt-4 xl:mt-0"
              />
            </div>
            <div className="col-span-3">
              <h1 className="font-bold text-xl text-customPurple uppercase mb-2">
                Entrega de
                <br />
                material e epi's
              </h1>
              <p className="text-xs">
                Garante que os registros de entrega e devolução de equipamentos
                sejam realizados de maneira prática e eficiente. Com essa
                funcionalidade é possível saber para quem foram entregues os
                materiais, e detalhes sobre a devolução dos mesmos.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center xl:justify-center">
          <div>
            <img
              src={img3}
              alt="ADX Software"
              className="w-auto h-auto max-h-96 -ml-48"
            />
          </div>

          <div className="pl-0 xl:pl-10 xl:-mt-20">
            <h1 className="font-bold text-xl text-customPurple uppercase mb-2 pl-14">
              Reparo de
              <br />
              Equipamento
            </h1>
            <p className="text-xs">
              O reparo de equipamento é um processo que envolve a restauração ou
              correção de um equipamento para restaurar seu funcionamento normal
              ou para corrigir falhas e danos.
              <br /><br/>
              Esse procedimento pode abranger a substituição de itens
              defeituosas, a aplicação de ajustes e outras intervenções
              necessárias para devolver o equipamento à condição operacional
              adequada. Você vai conseguir criar um checklist específico para
              esta funcionalidade. O objetivo do reparo é prolongar a vida útil
              do equipamento, otimizar seu desempenho e evitar a necessidade de
              substituição prematura.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default slide4;
