import React from "react";
import img1 from "../../../assets/imgs/food/14.jpg";
import img2 from "../../../assets/imgs/food/16.jpg";
import img3 from "../../../assets/imgs/food/15.jpg";

const slide3 = () => {
  return (
    <section className="w-full flex flex-col items-center justify-center min-h-screen relative">
      <div className="absolute left-0 top-10 w-40 h-40 bg-customPurple rounded-full z-10 transform -translate-x-24 hidden xl:block" />
      <div className="w-full max-w-7xl grid grid-cols-5 text-left min-h-screen py-20 gap-8">
        <div className="col-span-2 py-10">
          <img src={img1} alt="ADX Software" className="h-auto w-auto"/>
          <h1 className="text-customPurple text-xl font-bold uppercase mb-2 -mt-14">
            Checklist de segurança
            <br />
            do trabalho
          </h1>
          <p className="text-xs">
            Fundamental para garantir a máxima eficiência operacional e evitar
            qualquer desperdício de tempo, esta funcionalidade é essencial para
            fornecer um serviço altamente personalizado.
            <br />
            <br /> Ninguém conhece o seu negócio tão bem quanto você e, com essa
            ferramenta, você terá a liberdade de criar suas próprias vistorias.
            Além disso, ela oferece recursos avançados, como captura de fotos em
            tempo real, sem acesso a galeria do celular, rastreamento de
            localização e muito mais.
          </p>
        </div>

        <div className="col-span-3 py-10  flex flex-col justify-center">
          <div className="grid grid-cols-2">
            <div>
              <h1 className="text-customPurple text-xl font-bold uppercase mb-2">
                Checklist de segurança
                <br />
                do trabalho
              </h1>
              <p className="text-xs">
                Se sua empresa atua com refeições transportadas, temos a solução
                ideal para ajudá-lo a controlar todo o processo.
                <br />
                <br />
                Desde o gerenciamento da quantidade de recipientes ou hotboxes
                disponíveis em sua empresa, com identificação individual para
                cada um, permitindo que você rastreie sua localização. Além
                disso, nossa solução permite o controle detalhado de todas as
                rotas dos motoristas e quais recipientes estão atribuídos a cada
                um deles.
              </p>
            </div>
            <div>
              <img src={img2} alt="ADX Software" />
            </div>
          </div>

          <div className="grid grid-cols-2">
            <div className="flex flex-col items-end pr-10">
            <img src={img3} alt="ADX Software" className="max-w-72"/>
            </div>
            <div className="justify-end flex flex-col">
            <h1 className="text-customPurple text-xl font-bold uppercase mb-2">
                Checklist controle
                <br />
                temperatura dos<br/>alimentos
              </h1>
              <p className="text-xs pr-24">
              Funcionalidade especificamente  desenvolvida para te ajuda a mensurar a  temperatura adequada de todos os tipos de  produtos e dietas fornecidas pela sua  empresa, garantindo a segurança alimentar e  o bem estar de todos os seus clientes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default slide3;
