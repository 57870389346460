import React, { useState, useEffect } from "react";
import logo from "../../assets/imgs/logo001.png";
import MegaMenu from "./megaseg"; // Assuming MegaMenu is in the same folder
import Icon from "../../assets/imgs/iconheader.svg";
import Svg1 from "../../assets/imgs/svg1.svg";
import Svg2 from "../../assets/imgs/svg2.svg";
import Svg3 from "../../assets/imgs/svg3.svg";

const Header = () => {
  const [isMegaMenuOpen, setMegaMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGestoresPageOpen, setIsGestoresPageOpen] = useState(false); // New state for "Para gestores" page

  const handleMouseEnter = () => {
    setMegaMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setMegaMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault();
    };

    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");

      // Add event listeners to prevent scrolling
      window.addEventListener("scroll", handleScroll, { passive: false });
      window.addEventListener("wheel", handleScroll, { passive: false });
      window.addEventListener("touchmove", handleScroll, { passive: false });
    } else {
      document.body.classList.remove("overflow-hidden");

      // Remove event listeners when not needed
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    }

    // Cleanup function to remove event listeners when component unmounts or isMenuOpen changes
    return () => {
      document.body.classList.remove("overflow-hidden");
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    };
  }, [isMenuOpen]); // Run this effect when 'isMenuOpen' changes

  return (
    <div>
      <div className="hidden sm:block">
        <header className="relative z-10 my-8">
          {/* Header */}
          <div className="flex items-center justify-between max-w-[90vw] mx-auto h-full">
            <div className="flex items-center">
              <a href="../">
                <img
                  src={logo}
                  alt="Logo"
                  className="h-14 sm:h-10 hover:scale-105 transition-transform duration-100"
                />
              </a>
              <span className="ml-2 text-lg font-semibold"></span>
            </div>
            <div className="flex text-xl space-x-8 text-purple-600">


              <div
                className="flex flex-row items-center"
                onMouseEnter={(e) => {
                  handleMouseEnter(e);
                  const img = e.currentTarget.querySelector("img");
                  const link = e.currentTarget.querySelector("a");
                  img.style.transform = "rotate(90deg)";
                  link.style.textDecoration = "underline"; // Add underline on hover
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e);
                  const img = e.currentTarget.querySelector("img");
                  const link = e.currentTarget.querySelector("a");
                  img.style.transform = "rotate(-90deg)";
                  link.style.textDecoration = "none"; // Remove underline when not hovering
                }}
              >
                  <a href="#">Segmentos</a>
                <img
                  src={Icon}
                  className="h-3 -rotate-90"
                  style={{
                    transition: "transform 0.5s ease",
                  }}
                />
              </div>

              <a href="#" className="hover:underline">
                Aprenda
              </a>
              <a href="indiqueganhe" className="hover:underline">
                Indique e Ganhe
              </a>
            </div>
            <div>
              <button className="bg-purple-600 text-white py-4 px-6 rounded-full font-bold">
                TESTE GRÁTIS
              </button>
            </div>
          </div>

          {/* Mega Menu */}
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <MegaMenu isOpen={isMegaMenuOpen} />
          </div>
        </header>
      </div>

      {/* Mobile Navbar */}
      <div className="flex flex-row sm:hidden items-center justify-between p-4 bg-white">
        <div className="flex items-center">
          <div className="text-white rounded-full p-2">
            <a href="../"><img src={logo} className="h-6"/></a>
          </div>
        </div>

        <div className="flex items-center">
          <button className="bg-purple-600 text-white py-2 px-4 rounded-full font-bold text-sm">
            Teste Grátis
          </button>
        </div>

        <div className="flex items-center">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="focus:outline-none"
          >
            <div className="space-y-1">
              <div className="w-6 h-0.5 bg-purple-600"></div>
              <div className="w-6 h-0.5 bg-purple-600"></div>
              <div className="w-6 h-0.5 bg-purple-600"></div>
            </div>
          </button>
        </div>
      </div>

      {/* Side Menu */}
      <div
        className={`sm:hidden absolute top-16 right-0 w-full h-full bg-slate-200 shadow-lg transform transition-transform duration-300 z-50 ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        {!isGestoresPageOpen ? (
          <div className="p-4 text-left mr-4">
            <h2 className="text-purple-700 font-bold">Segmentos</h2>
            <ul className="mt-4">
              <li className="mt-2">
                <a
                  href="#"
                  className="text-gray-800 hover:text-purple-600"
                  onClick={() => setIsGestoresPageOpen(true)} // Open "Para gestores" page
                >
                  Para gestores
                </a>
                <p>Veja soluções feitas para gestores de equipe de campo</p>
              </li>
              <li className="mt-2">
                <a href="#" className="text-gray-800 hover:text-purple-600">
                  Aprenda
                </a>
              </li>
              <li className="mt-2">
                <a href="indiqueganhe" className="text-gray-800 hover:text-purple-600">
                  Indique e Ganhe
                </a>
              </li>

            </ul>
          </div>
        ) : (
          <div className="p-4 text-left">
            {/* Content for "Para gestores" page */}
            <button
              className="text-purple-700 font-bold mb-4"
              onClick={() => setIsGestoresPageOpen(false)} // Go back to the main menu
            >
              &larr; Back
            </button>
            <h2 className="text-purple-700 font-bold">Para Gestores</h2>
            {/* Insert your custom content here */}
            <p>Veja soluções feitas para gestores de equipe de campo</p>
            <div className="pt-4 space-y-6 text-left">
            <a href="/terceirizacao">
              <div className="flex items-center p-4 bg-slate-100 bg-opacity-50 rounded-lg border border-solid border-slate-200 shadow-lg">
                <img
                  src={Svg1}
                  alt="My Icon"
                  className="w-8 h-8 text-purple-500 mr-3"
                />
                
                <div>
                  <h4 className="text-purple-600 font-semibold">Toolbit</h4>
                  <p className="text-gray-600">
                    Software para medir, evidenciar e gerenciar atividades para
                    operações de Segurança e Terceirização de Serviços.
                  </p>
                </div>
              </div></a>
              <a href="/food">
              <div className="flex items-center p-4 bg-slate-100 bg-opacity-50 rounded-lg border border-solid border-slate-200 shadow-lg">
                <img
                  src={Svg2}
                  alt="My Icon"
                  className="w-8 h-8 text-purple-500 mr-3"
                />

                <div>
                  <h4 className="text-purple-600 font-semibold">Nutcin</h4>
                  <p className="text-gray-600">
                    Plataforma completa para gerenciar as operações da sua
                    empresa de refeição coletiva.
                  </p>
                </div>
              </div></a>
              <a href="/gestao">
              <div className="flex items-center p-4 bg-slate-100 bg-opacity-50 rounded-lg border border-solid border-slate-200 shadow-lg">
                <img
                  src={Svg3}
                  alt="My Icon"
                  className="w-8 h-8 text-purple-500 mr-3"
                />

                <div>
                  <h4 className="text-purple-600 font-semibold">WasteWay</h4>
                  <p className="text-gray-600">
                    Gerencie a coleta de resíduos da sua empresa.
                  </p>
                </div>
              </div>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
