import React, { useState } from "react";
import img1 from "../../assets/imgs/terceirizacao/1.jpg";
import img2 from "../../assets/imgs/terceirizacao/22.jpg";
import img3 from "../../assets/imgs/terceirizacao/3.jpg";
import img4 from "../../assets/imgs/terceirizacao/23.jpg";
import img5 from "../../assets/imgs/terceirizacao/24.jpg";
import img6 from "../../assets/imgs/terceirizacao/25.jpg";
import img7 from "../../assets/imgs/terceirizacao/26.jpg";
import img8 from "../../assets/imgs/terceirizacao/8.jpg";
import img9 from "../../assets/imgs/terceirizacao/9.jpg";


const Slidermobile = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Slide templates as an array
  const items = [
    // Slide 1: Text with a Background Image
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img1} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Dashboard e App
        </h2>
        <p className="text-center">
          Você vai conseguir acompanhar tudo pelo painel gerencial enquanto sua
          equipe de campo usa um aplicativo Android para registro das atividades
          na ponta. Você vai conseguir controlar a agenda, falar com seus
          colaboradores e clientes, enxergar métricas através de relatórios e
          gráficos e ter a certeza que até o cliente do seu cliente está
          satisfeito com a sua prestação de serviços.
        </p>
      </div>
    </div>,

    // Slide 2: Card Layout with an Image and Description
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img2} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Controle da rotina diária e produtividade dos colaboradores
        </h2>
        <p className="text-center">
          A agenda é uma ferramenta essencial que o supervisor irá utilizar como
          forma de organização e direcionamento, para que assim seja possível
          saber para onde ele deve ir nas suas próximas visitas.
        </p>
      </div>
    </div>,

    // Slide 3: Simple Quote with a Background Color
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img3} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Checklist customizável
        </h2>
        <p className="text-center">
          Maximize a eficiência operacional e a personalização do serviço
          através da criação de checklists customizáveis com fotos em tempo
          real, evitando desperdícios de tempo e garantindo a precisão das
          informações.
        </p>
      </div>
    </div>,
    // Slide 4: Simple Quote with a Background Color
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img4} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Checkout de hospedagem
        </h2>
        <p className="text-center">
          Funcionalidade voltada para hospitais e hotelarias, nela, o
          colaborador irá escolher o tipo de limpeza a ser realizada,
          cronometrando o tempo de serviço e registrando os processos realizados
          por meio de fotos e observações, tudo através de checklists práticos e
          objetivos.
        </p>
      </div>
    </div>,
    // Slide 5: Simple Quote with a Background Color
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img5} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Vistoria de local
        </h2>
        <p className="text-center">
          Funcionalidade voltada para registro de locais através de QR Code.
          Nela, o colaborador irá escolher o local a ser realizada a inspeção,
          registrando com fotos e observações, através de checklists práticas e
          objetivas.
        </p>
      </div>
    </div>,
    // Slide 6: Simple Quote with a Background Color
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img6} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Inspeção de colaborador
        </h2>
        <p className="text-center">
          Com gráficos, relatórios e indicadores a Audax Terceirização de
          Serviços apresenta as operações da empresa, levantando índices de
          produtividade dos seus colaboradores, os principais problemas da
          organização, bem como tantas outras informações relevantes para a
          gestão.
        </p>
      </div>
    </div>,
    // Slide 7: Simple Quote with a Background Color
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img7} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Vistoria de veículos
        </h2>
        <p className="text-center">
          Registre as condições dos veículos da sua companhia por meio de fotos,
          garantindo a eficiência no processo de vistoria e verificação de ordem
          dos equipamentos do automóvel (lanterna, luz de freio, bateria, entre
          outros).
        </p>
      </div>
    </div>,
    // Slide 8: Simple Quote with a Background Color
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img8} alt="Product" className="w-auto h-60 mb-4 " />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Acompanhamento de não conformidades
        </h2>
        <p className="text-center">
          Reduza em até 100% as multas e sanções aplicadas pelos órgãos
          regulamentadores e seus clientes. Atue proativamente e preventivamente
          nos processos de certificação e recertificação das ISOS.
          <li>Tenha o controle total das não conformidades;</li>
          <li>Identifique problemas na operação;</li>
          <li>Atue em conformidades com as sla’s contratadas.</li>
        </p>
      </div>
    </div>,
    // Slide 9: Simple Quote with a Background Color
    <div className="w-full  flex flex-col justify-center items-center ">
      <img src={img9} alt="Product" className="w-auto h-60 mb-4" />
      <div className="px-10">
        <h2 className="text-xl font-semibold mb-2 uppercase text-customPurple">
          Registro diário de ocorrência (RDO)
        </h2>
        <p className="text-center">
        Funcionalidade voltada para o registro de irregularidades internas e externas, garantindo o acompanhamento eficiente das mesmas até o fechamento da ocorrência. 
        Você vai enxergar como uma sinaleira suas ocorrências e categorizar de acordo com a sua operação
        </p>
      </div>
    </div>,
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="p-6 relative w-full max-w-md mx-auto">
      <div className="flex justify-center items-center rounded-lg">
        {items[currentIndex]}
      </div>
      <div className="absolute inset-0 flex justify-between items-center px-4">
        <button
          onClick={handlePrev}
          className="bg-customPurple p-3 rounded-full  text-white font-bold flex items-center justify-centerw"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          onClick={handleNext}
          className="bg-customPurple p-3 rounded-full  text-white font-bold flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Slidermobile;
