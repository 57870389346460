import React from "react";
import Slider from "react-slick";
import img1 from "../../assets/imgs/home/1.png";
import img2 from "../../assets/imgs/home/2.png";
import img3 from "../../assets/imgs/home/3.png";
import img4 from "../../assets/imgs/home/4.png";
import img5 from "../../assets/imgs/home/5.png";
import img6 from "../../assets/imgs/home/6.png";
import img7 from "../../assets/imgs/home/7.png";
import img8 from "../../assets/imgs/home/8.png";
import img9 from "../../assets/imgs/home/9.png";

const ImageSlider = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 4500,
    slidesToShow: 5, // Adjusted to show 5 slides at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="container max-w-full">
        <Slider {...settings}>
          <div className="px-4">
            <img
              src={img1}
              alt="Image 1"
              className="w-full max-h-[80px] object-contain"
            />
          </div>
          <div className="px-4">
            <img
              src={img2}
              alt="Image 2"
              className="w-full max-h-[80px] object-contain"
            />
          </div>
          <div className="px-4">
            <img
              src={img3}
              alt="Image 3"
              className="w-full max-h-[80px] object-contain"
            />
          </div>
          <div className="px-4">
            <img
              src={img4}
              alt="Image 4"
              className="w-full max-h-[80px] object-contain"
            />
          </div>
          <div className="px-4">
            <img
              src={img5}
              alt="Image 5"
              className="w-full max-h-[80px] object-contain"
            />
          </div>
          <div className="px-4">
            <img
              src={img6}
              alt="Image 6"
              className="w-full max-h-[80px] object-contain"
            />
          </div>
          <div className="px-4">
            <img
              src={img7}
              alt="Image 7"
              className="w-full max-h-[80px] object-contain"
            />
          </div>
          <div className="px-4">
            <img
              src={img8}
              alt="Image 8"
              className="w-full max-h-[80px] object-contain"
            />
          </div>
          <div className="px-4">
            <img
              src={img9}
              alt="Image 9"
              className="w-full max-h-[80px] object-contain"
            />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default ImageSlider;
