import React, { useState, useEffect } from "react";
import img1 from "../../assets/imgs/gestao/1.jpg";
import img2 from "../../assets/imgs/gestao/2.jpg";
import img3 from "../../assets/imgs/gestao/3.jpg";

const BannerComponent3 = () => {
  const [currentImage, setCurrentImage] = useState(0);

  // Use the imported images directly
  const images = [img1, img2, img3];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 1000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <h1 className="hidden sm:block">
      <div
        className="flex items-center justify-start pl-28 relative"
        style={{
          backgroundImage: `url(${images[currentImage]})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
          height: "100vh",
          maxHeight: "820px",
          transition: "background-image 1s ease-in-out",
        }}
      >
        <div className="text-left relative">
          <h1 className="text-customPurple font-sans text-4xl">
            <b>OTIMIZE O CONTROLE</b><br/>DA SUA <b>OPERAÇÃO</b>DE<br/><b>COLETA DE RESÍDUOS</b>
          </h1>
          <p className="pr-[40vw] text-customPurple text-xl">Facilite o processo de criação e gestão das rotas e solicitações do setor logístico, tornando a operação mais eficiente.</p>
          <button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
            SOLICITE SEU DIAGNÓSTICO
          </button>
        </div>
      </div>
    </h1>
  );
};

export default BannerComponent3;
