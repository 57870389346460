import React from "react";
import img1 from "../../assets/imgs/food/4.jpg";
import img2 from "../../assets/imgs/food/6.jpg";
import BannerComponent from "../../components/rotatebanner/bannercomponent2";
import imgfood from "../../assets/imgs/food/5.jpg";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import YouTubeVideo from "../../components/youtubevideo";
import ContinuousSlider from "./sliderdesk";
import Slidermobile from "./slidermobile";
import icon from "../../assets/imgs/food/icon.jpg";
import Svg from "../../assets/imgs/terceirizacao/svg.svg";

const food = () => {
  return (
    <div>
      <div className="hidden md:block"></div>
      <div className="mx-auto pb-20">
        <Header />
        <BannerComponent />
        <div className="md:hidden">
          <img src={img1} alt="Banner" className="w-full" />
          <div class="firstsec firstsec-mobile">
            <div className="relative -mt-80 sm:hidden py-10 text-left p-8">
              <h1 className="text-customPurple font-sans text-xl">
                <b>Plataforma completa</b> para
                <br />
                <b>gerenciar as operações</b> da <br />
                sua empresa{" "}
                <b>
                  de refeição <br />
                  coletiva
                </b>
              </h1>
              <p className="pt-4 text-customPurple">
                A Toolbit é a melhor e mais
                <br />
                Aumente a qualidade da sua produção, assegure o crescimento da
                performance dos seus colaboradores e reduza riscos e custos
                operacionais!
              </p>
              <button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
                SOLICITE SEU DIAGNÓSTICO
              </button>
            </div>
          </div>
        </div>
        <div className="bg-customPurple py-10 bg-pattern flex flex-col items-center">
          <div className="items-center md:w-full  md:max-w-[90vw] md:grid md:grid-cols-2 z-40 p-8">
            <div>
              <p className="flex flex-col text-white font-bold sm:text-left justify-center h-full sm:pr-10 sm:2xl">
                O Nutcin vai lhe auxiliar nas auditorias que você realiza nos
                seus clientes, com checklists para adequação estrutural,
                armazenamentos, atendimento, distribuição, processos produtivos,
                controle de resíduos, segurança do trabalho e muito mais.
                <br />
                <br />O Nutcin oferece suporte completo para atender e
                adaptar-se a todas as necessidades dos seus clientes
              </p>
            </div>
            <div className="flex flex-col">
              {" "}
              <div>
                <img
                  src={imgfood}
                  alt="dashboard"
                  className="pt-10 sm:pt-0 z-10 h-full w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 py-8">
          <h1 className="text-2xl font-bold uppercase text-customPurple mb-4 sm:text-4xl sm:pt-10">
            Tenha ao seu lado
            <br />
            uma plataforma que:
          </h1>
        </div>

        <div className="bg-gray-100 mx-auto items-center pt-7 px-[6vw] pb-4">
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-center">
    
    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="flex text-customPurple font-semibold sm:text-xl">
        Acompanha e registra
      </div>
      <p>
        as atividades da sua equipe
      </p>
    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="text-customPurple font-semibold sm:text-xl">
        Controla todos
      </div>
      <p>
        os recursos da sua operação
      </p>
    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="text-customPurple font-semibold sm:text-xl">
        Possui checklists customizáveis
      </div>
      <p>
        para se adequar as realidades e necessidades da sua empresa
      </p>
    </div>
    
  </div>

  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center mt-4">
    
    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="text-customPurple font-semibold sm:text-xl">
        Controle de ponta a ponta
      </div>
      <p>
        desde o recebimento dos insumos até a entrega final do produto
      </p>
    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow sm:min-h-44">
      <div className="text-customPurple font-semibold sm:text-xl">
        Dashboard personalizável
      </div>
      <p>
        que facilita a visualização de informações cruciais para a sua gestão
      </p>
    </div>

  </div>
</div>

        <div className="sm:hidden grid grid-cols-1 gap-4 text-customPurple md:grid-cols-4 sm:grid-cols-4">
          <div className="bg-gray-300 p-3 mx-4 rounded-lg min-h-20 flex flex-col justify-center">
            <p className="font-bold text-xs">
              Acompanha e registra as atividades da sua equipe
            </p>
          </div>
          <div className="bg-gray-300 p-3 mx-4 rounded-lg min-h-20 flex flex-col justify-center">
            <p className="font-bold text-xs">
              Controla todos os recursos da sua operação
            </p>
          </div>
          <div className="bg-gray-300 p-3 mx-4 rounded-lg min-h-20 flex flex-col justify-center">
            <p className="font-bold text-xs">
              Possui checklists customizáveis para se adequar as realidades e
              necessidades da sua empresa
            </p>
          </div>
          <div className="bg-gray-300 p-3 mx-4 rounded-lg min-h-20 flex flex-col justify-center">
            <p className="font-bold text-xs">
              Controla de ponta a ponta desde o recebimento dos insumos até a
              entraga final do produto
            </p>
          </div>
          <div className="bg-gray-300 p-3 mx-4 rounded-lg min-h-20 flex flex-col justify-center">
            <p className="font-bold text-xs">
              Dashboard personalizável que facilita a visualização de
              informações cruciais para a sua gestão
            </p>
          </div>
        </div>

        <h1 className="m-8 text-2xl sm:text-4xl font-bold uppercase text-customPurple mb-4 mt-20">
          Monitoramento de
          <br />
          não confirminadas
          <br />
          na palma da sua mão
        </h1>

          <div className="grid grid-cols-1 sm:grid-cols-2 sm:max-w-[35vw] mx-auto">
            <p className=" sm:ml-0 sm:mt-10">
              Uma boa gestão antecipa problemas, focando em planos de ação para
              conter e corrigir não conformidades.
              <br />
              <br />
              Com o Nutcin é possível gerar relatórios da operação, gerando
              evidências para a prestação de contas de clientes públicos e
              privados.
            </p>
            <div className="items-center flex flex-col justify-center">
              <img
                src={img2}
                alt="ADX Dashboard"
                className="h-[80vw] sm:h-96 w-auto"
              />
            </div>
          </div>

        <div className="flex flex-col bg-customPurple text-white font-bold items-center py-6 justify-center sm:text-3xl">
          <p>
            Descubra as funcionalidades
            <br />
            do sistema Nutcin
          </p>
        </div>
        <div className="hidden md:block my-20 sm:my-0 sm:pt-5">
        <div className="flex flex-row justify-center gap-5">
            <p className="text-2xl text-customPurple self-center">
              Arraste para o lado
            </p>
            <img
              src={Svg}
              className="transition-transform duration-500 hover:rotate-45"
            />
          </div>
        </div>
        <div className="hidden md:block">
          {" "}
          <ContinuousSlider />
        </div>
        <div className="sm:hidden block py-20">
          <Slidermobile />
        </div>

        <div className="bg-customPurple py-20">
          <p className="text-2xl sm:block sm:text-4xl text-white font-bold">
            Saiba mais:
          </p>
        </div>

        <div className="md:-mt-0 flex flex-col items-center">
          <div className="-mt-10 sm:-mt-20 container px-6 md:px-48 md:py-8 z-40">
            {" "}
            <YouTubeVideo videoId="LV2-cjRpzfU" />
            <p className="px-7 sm:text-2xl py-10">
          A partir dos relatórios de checklists o gestor pode visualizar de
          forma prática e eficiente as evidências das informações coletadas. O
          que facilita muito a resolução de possíveis problemas que surgem ao
          longo da operação.
        </p>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
};

export default food;
