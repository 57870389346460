import React from "react";
import Svg1 from "../../assets/imgs/svg1.svg";
import Svg2 from "../../assets/imgs/svg2.svg";
import Svg3 from "../../assets/imgs/svg3.svg";

const MegaMenu = ({ isOpen }) => {
  return (
    <div
      className={`absolute top-14 left-0 right-0 bg-white shadow-lg rounded-lg p-8 z-50 border border-solid border-blue-100 transform transition-transform duration-300 ease-in-out ${
        isOpen ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0"
      } origin-top`}
      style={{ maxWidth: "900px", margin: "0 auto" }}
    >
      <div className="flex">
        {/* Left Section */}
        <div className="w-1/3 p-4 text-left bg-slate-100 max-h-[160px] rounded-lg border border-solid border-slate-200 shadow-lg">
          <h3 className="text-purple-600 text-xl font-bold mb-4">
            Para Gestores
          </h3>
          <p className="text-gray-600">
            Veja soluções feitas para gestores de equipe de campo
          </p>
        </div>
        {/* Divider */}
        <div className="w-[1px] bg-gray-200 mx-4"></div>
        {/* Right Section */}
        <div className="w-2/3 space-y-6 text-left">
          <a href="/terceirizacao">
            <div className="flex items-center p-4 bg-slate-100 bg-opacity-50 max-h-[160px] rounded-lg border border-solid border-slate-200 shadow-lg mb-5">
              <img
                src={Svg1}
                alt="My Icon"
                className="w-8 h-8 text-purple-500 mr-3"
              />

              <div>
                <h4 className="text-purple-600 font-semibold">Toolbit</h4>
                <p className="text-gray-600">
                  Software para medir, evidenciar e gerenciar atividades para
                  operações de Segurança e Terceirização de Serviços.
                </p>
              </div>
            </div>
          </a>
          <a href="/food">
            <div className="flex items-center p-4 bg-slate-100 bg-opacity-50 max-h-[160px] rounded-lg border border-solid border-slate-200 shadow-lg mb-5">
              <img
                src={Svg2}
                alt="My Icon"
                className="w-8 h-8 text-purple-500 mr-3"
              />

              <div>
                <h4 className="text-purple-600 font-semibold">Nutcin</h4>
                <p className="text-gray-600">
                  Plataforma completa para gerenciar as operações da sua empresa
                  de refeição coletiva.
                </p>
              </div>
            </div>
          </a>
          <a href="/gestao">
            <div className="flex items-center p-4 bg-slate-100 bg-opacity-50 max-h-[160px] rounded-lg border border-solid border-slate-200 shadow-lg">
              <img
                src={Svg3}
                alt="My Icon"
                className="w-8 h-8 text-purple-500 mr-3"
              />

              <div>
                <h4 className="text-purple-600 font-semibold">WasteWay</h4>
                <p className="text-gray-600">
                  Gerencie a coleta de resíduos da sua empresa.
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MegaMenu;
